import React, { ReactNode, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import './LoginModal.css';
import { createAction, hubInvoke } from '../state/actions';
import { login, validateToken, Sessions as SessionsApi } from '../utils/api';
import { loggedIn as setLoggedIn } from '../state/reducer';
import GuestManager from './GuestManager';
import { joinSession } from '../middleware/signalr';
import Sessions from './Sessions';
import Help from './Help';

export default function LoginModal({ onClose }: { onClose?: () => void }) {
    const [submitted, setSubmitted] = useState(false);
    const [sessionName, setSessionName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [tokenChecked, setTokenChecked] = useState(false);
    const { sessionId, sessionValidated, loggedIn, userName } = useAppSelector((state) => state.main);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const checkToken = async () => {
            const token = localStorage.getItem("token");
            if (token) {
                await dispatch(validateToken(token));
            }
            else dispatch(setLoggedIn(false));

            setTokenChecked(true);
        };


        checkToken();
    }, []);

    useEffect(() => {
        if (sessionId) {
            dispatch(joinSession(sessionId));
            onClose && onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId, dispatch]);

    // useEffect(() => {
    //     if (loggedIn) {
    //         dispatch(startHubConnection);
    //     }
    // }, [loggedIn]);

    return <>
        <div className='modal'>
            <div className="form">
                {tokenChecked && <div id="login">
                    {/* Session Code: <input type="text" value={sessionId} onChange={e => { setSessionId(e.target.value); }} /><br /> */}
                    {loggedIn !== true && <div id="loginForm" className='main-section'>
                        <h1>Interviewer Login</h1>
                        Email: <input type="text" value={email} onChange={(e) => { setEmail(e.target.value); }} /><br />
                        Password: <input type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} /><br />
                        <button onClick={() => { dispatch(login(email, password)) }}>Login</button>
                        {loggedIn !== false && <div>Error: {loggedIn}</div>}
                    </div>}
                    {loggedIn === true && <div className='main-section'>
                        <div>Welcome back {userName}!</div>
                        Session Name: <input type="text" value={sessionName} onChange={({target}) => { setSessionName(target.value); }} />
                        <button onClick={() => {
                            // dispatch(hubInvoke("createSession"));
                            // dispatch(createAction("main/validateSessionId", true));
                            // setSubmitted(true);
                            dispatch(SessionsApi.create(sessionName))
                        }}>Create Session</button>
                        <Sessions />
                    </div>}
                    <div id="help" className='right-section'>
                        <Help />
                    </div>
                </div>}
            </div>
        </div>
    </>;
}