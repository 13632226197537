import { useState } from "react";
import { Guest } from "../utils/api";
import { useAppDispatch, useAppSelector } from "../state/hooks";

export default function GuestManager() {
    const dispatch = useAppDispatch();
    const { sessionId } = useAppSelector((state) => state.main);
    const [name, setName] = useState("");
    const [guests, setGuests] = useState<any[]>([]);

    const addGuest = async () => {
        const result = await dispatch(Guest.addGuest(name, sessionId));
        setGuests([...guests, { name, code: result }]);
        setName("");
    };

    function copyLink(code: string) {
        console.log("http://localhost:3000?code=" + code);
    }

    function removeGuest(guest: any) {
        
    }

    return <div>
        Name: <input type="text" value={name} onChange={({ target }) => { setName(target.value) }} />
        <button onClick={() => { addGuest(); }}>Add</button>
        {/* <button onClick={() => { setGuests((g) => { return [...g, name]; }); setName(""); }}>Add</button> */}
        {guests.length > 0
            && guests.map((g, i) => <div key={i}>{g.name} 
            {/* <button onClick={() => { copyLink(g.code) }}>Copy</button> */}
                {/* <button onClick={() => { removeGuest(g) }}>Delete</button> */}
                {`${window.location.href}?code=${g.code}`}
            </div>)
        }
    </div>;
}