import jwtDecode from "jwt-decode";

export const Helpers = {
    isAdmin: (jwt: string) => Helpers.isInRole(jwt, "admin"),
    isInterviewer: (jwt: string) => (Helpers.isInRole(jwt, "interviewer")),
    isInRole: (jwt: string, role: string | string[]) => {
        const claims = jwtDecode<any>(jwt);
        const roles = claims["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] as [];
        if (Array.isArray(role)) {
            return roles?.length > 0 && roles.some(r => r === role);
        } else {
            return roles?.length > 0 && roles.find(r => r === role) !== undefined;
        }
    }
};

export default Helpers;