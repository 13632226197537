import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Editor from './controls/editor';
import { useAppDispatch, useAppSelector } from './state/hooks';
import { hubInvoke } from './state/actions';
import 'react-toastify/dist/ReactToastify.css';
import LoginModal from './views/LoginModal';
import { setUserName, transmitPosition } from './state/reducer';
import qs from 'qs';
import { exchange, Sessions, validateToken } from './utils/api';
import jwtDecode from 'jwt-decode';
import GuestManager from './views/GuestManager';
import { joinSession, leaveSession } from './middleware/signalr';
import Helpers from './utils/helpers';
import ConnectedUsers from './controls/ConnectedUsers';

function App() {
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
  const [modal, setModal] = useState(true);
  const [currentSessionName, setCurrentSessionName] = useState("");
  const [interviewer, setInterviewer] = useState(false);
  const dispatch = useAppDispatch();
  const { transmitPosition: transmitting, connected, sessionId, connectionId, inControl } = useAppSelector((state) => state.main);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    window.addEventListener('close', () => {

    });

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [dispatch]);

  useEffect(() => {
    if (window.location.search.length > 1) {
      const kvp = qs.parse(window.location.search.slice(1));
      const code = kvp["code"];
      localStorage.removeItem("token");
      if (code) {
        (async () => {
          const token = await dispatch(exchange(code as string));
          const jwt = jwtDecode<any>(token);
          localStorage.setItem("token", token);
          const jwtSessionId = jwt["SessionId"];
          const displayName = jwt["DisplayName"];
          dispatch(joinSession(jwtSessionId));
          dispatch(setUserName(displayName));
          setModal(false);
        })();
      }
    }
  }, []);

  useEffect(() => {
    if (interviewer === true) {
      // For interviewers, start this off disabled
      dispatch(transmitPosition(false));
    }
  }, [dispatch, interviewer]);

  useEffect(() => {
    if (interviewer !== true) return;
    setModal(sessionId === '');

    if (sessionId !== '') {
      (async () => {
        const s = (await dispatch(Sessions.get(sessionId)));
        setCurrentSessionName(s[0].name);
      })();
    }
  }, [dispatch, sessionId, interviewer]);

  const getConnections = () => {
    dispatch(hubInvoke("getConnections", sessionId));
  }

  // const sessionFieldChanged = (e: any) => {
  //   dispatch(createAction(SESSION_CREATED, e.target.value));
  // };

  const setAdmin = () => {
    dispatch(hubInvoke("setAdmin", sessionId, true));
    getConnections();
  }

  const setInControl = (e: any) => {
    dispatch(hubInvoke("setControl", sessionId, e.target.value));
  }

  const onClose = async () => {
    setModal(false);
    const token = localStorage.getItem("token");
    if (token && await dispatch(validateToken(token))) {
      setInterviewer(Helpers.isInterviewer(token));
    }
  }

  return (
    <div>
      <Editor height={windowSize[1] - 200} />
      {connected && inControl === connectionId && <div>You are currently in control!</div>}
      {inControl !== connectionId && <div>You are currently viewing! Changes you make will be overwritten by remote.</div>}
      {interviewer && sessionId !== "" && <>
        <button onClick={() => { dispatch(leaveSession(sessionId)) }}>Leave Session "{currentSessionName}"</button>
        <input type="checkbox" checked={transmitting} onChange={() => { dispatch(transmitPosition(!transmitting)) }} />
        <GuestManager />
        <button onClick={getConnections}>Connections</button>
        <button onClick={setAdmin}>Admin</button>
        <ConnectedUsers />
      </>}
      {modal && <LoginModal onClose={onClose} />}
      <ToastContainer />
    </div>
  );
}

export default App;
