import { combineReducers, createSlice } from "@reduxjs/toolkit";
import { CONNECT_STATE_CHANGED, CONTROL, SESSION_CREATED, SET_USER_NAME } from "./types";

type DefaultState = {
    sessionId: string,
    selection: any,
    inControl: string,
    userName: string,
    sessionValidated: boolean,
    connections: string[],
    connected: boolean,
    connectionId: string,
    text: string,
    transmitPosition: boolean,
    loggedIn: boolean | string,
    interviewer: boolean
};

const initialState: DefaultState = {
    sessionId: "",
    selection: undefined,
    inControl: "",
    userName: "",
    sessionValidated: false,
    connections: [],
    connected: false,
    connectionId: "",
    text: "",
    transmitPosition: true,
    loggedIn: false,
    interviewer: false
};

const main = createSlice({
    name: 'main',
    initialState,
    reducers: {

        "update": (state, { payload }) => ({ ...state, text: payload }),
        "selection": (state, { payload }) => ({ ...state, selection: payload }),
        // [SESSION_CREATED]: (state, { payload }) => {
        //     return { ...state, sessionId: payload }
        // },
        // "createSession": (state, { payload }) => {
        //     return { ...state, sessionId: payload }
        // },
        [CONTROL]: (state, { payload }) => /* inControl - the user/connectionId that can type into the IDE*/ ({ ...state, inControl: payload }),
        [CONNECT_STATE_CHANGED]: (state, { payload }) => ({ ...state, connectionId: payload.connectionId, connected: payload.connected }),
        "getConnections": (state, { payload }) => ({ ...state, connections: payload }),
        "setControl": (state, { payload }) => ({ ...state, inControl: payload }),
        setUserName: (state, { payload }) => ({ ...state, userName: payload }),
        "validateSessionId": (state, { payload }) => ({ ...state, sessionValidated: payload }),
        transmitPosition: (state, { payload }) => ({ ...state, transmitPosition: payload }),
        loggedIn: (state, { payload }) => ({ ...state, loggedIn: payload }),
        setSession: (state, { payload }) => ({...state, sessionId: payload })
        // setInterviewer: (state, { payload }) =>
        //     ({ ...state, interviewer: payload })
    }
});

export const { transmitPosition, loggedIn, setUserName, setSession } = main.actions;

const positions = createSlice({
    name: "positions",
    initialState: [] as string[],
    reducers: {
        updatePosition: (state, action) => {
            const { payload } = action;
            const name = payload.split("|")[1];
            const s = state.filter(p => !p.endsWith(name)) as string[];
            return [...s, payload];
        }
    }
});

export type Session = ({sessionId: string, name: string});
const sessions = createSlice({
    name: "sessions",
    initialState: [] as Session[],
    reducers: {
        updateSessions: (_state, action) => {
            const { payload } = action;
            return payload;
        }
    }
})

export const { updateSessions } = sessions.actions;

export default combineReducers({ main: main.reducer, positions: positions.reducer, sessions: sessions.reducer });