import { setInControl } from '../middleware/signalr';
import { useAppDispatch, useAppSelector } from '../state/hooks';

export default function ConnectedUsers() {
    const { connections, inControl, sessionId } = useAppSelector((state) => state.main);
    const dispatch = useAppDispatch();
    
    return <>
        {connections?.length &&
             connections.map((c: string) => {
             const [connectionId, name] = c.split(":");
             return <div key={c}>{inControl === connectionId ? "+" : ""}{name} <button onClick={() => {
                dispatch(setInControl(sessionId, connectionId));
             }}>Activate</button></div>}) 
        }
    </>;
}