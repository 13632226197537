import { useEffect, useState } from "react";
import { Sessions as SessionsApi } from "../utils/api";
import { joinSession } from "../middleware/signalr";
import { useAppDispatch, useAppSelector } from "../state/hooks";
export default function Sessions() {
    const dispatch = useAppDispatch();
    const sessionList = useAppSelector((state) => state.sessions);

    useEffect(() => {
        (async () => {
            await dispatch(SessionsApi.get());
        })();
    }, []);

    return <>
        {sessionList.length > 0 && <ul>
            {sessionList.map((s, i) => <li key={i} value={s.sessionId}>
                <button onClick={() => { dispatch(joinSession(s.sessionId)) }}>{s.name}</button>
                <button onClick={async () => {
                    await dispatch(SessionsApi.delete(s.sessionId));
                    dispatch(SessionsApi.get());
                }}>Delete</button>
            </li>)}
        </ul>
        }</>;
}