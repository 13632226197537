export default function Help() {
    return <>
        <h1>How to use this app</h1>
        <p>If you do not have a login you must request one to be created.</p>
        <h2>After logging in</h2>
        <ol>
            <li>Enter a name for the session so it's easy to identify and click Create Session</li>
            <li>Click the button with the session name to join the session</li>
            <li>Add a guest user: type the guest's name and click add</li>
            <li>Copy the generated URL and send to the guest. The code is valid for the current day.</li>
        </ol>
    </>;
}