import jwtDecode from "jwt-decode";
import { AppDispatch } from "..";
import { joinSession } from "../middleware/signalr";
import { loggedIn, Session, setUserName, updateSessions } from "../state/reducer";
const baseUrl = process.env.REACT_APP_API_URL;

const authFetch = async (url: string, options?: RequestInit) => {
    const response = await fetch(url, addAuthHeader(options));
    // if(response.ok) return response;

    if (response.status === 401) {
        localStorage.removeItem("token");
    }
    return response;
}

export const login = (email: string, password: string) => async (dispatch: any) => {
    const result = await fetch(baseUrl + "/auth/login", {
        body: JSON.stringify({ email, password }),
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json"
        }
    });
    if (result.ok) {
        const body = await result.json();
        localStorage.setItem("token", body["token"]);
        dispatch(loggedIn(true));
        // If you are logging in then the Name will always be set.
        // Only guests use DisplayName
        const userName = body["name"];
        dispatch(setUserName(userName));
    } else {
        dispatch(loggedIn(result.status));
    }
};

export const validateToken = (token: string) => async (dispatch: AppDispatch) => {
    const result = await fetch(baseUrl + `/auth/validate?token=${token}`);
    if (!result.ok) {
        dispatch(loggedIn(false));
        localStorage.removeItem("token");
    }
    else {
        const body = await result.json();
        dispatch(loggedIn(true));
        var jwt = jwtDecode<any>(token);
        // Guests use DisplayName since name is a guid and temporary user.
        const userName = Object.keys(jwt).includes("DisplayName") ? jwt["DisplayName"] : body["name"];
        dispatch(setUserName(userName));
    }
    return result.ok;
};

export const exchange = (code: string) => async () => {
    const response = await fetch(baseUrl + `/auth/exchange?code=${code}`);
    return await response.text();
};

export const Sessions = {
    get: (sessionId?: string) => async (dispatch: AppDispatch): Promise<Session[]> => {
        if (sessionId) {
            const result = await authFetch(baseUrl + `/session/${sessionId}`);
            if (!result.ok) return [];
            return [await result.json()];
        } else {
            const result = await authFetch(baseUrl + "/session");
            if (!result.ok) return [];
            const json = await result.json();
            dispatch(updateSessions(json));
            return json;
        }
    },
    create: (name: string) => async (dispatch: AppDispatch, getState: any) => {
        const response = await authFetch(baseUrl + "/session/create", {
            method: "POST",
            body: JSON.stringify(name),
            headers: { "Content-Type": "application/json" }
        });
        if (response.ok) {
            const sessionId = await response.text();
            dispatch(Sessions.get());
        }
    },
    delete: (sessionId: string) => async (): Promise<void> => {
        await authFetch(baseUrl + "/session?sessionId=" + sessionId,
            {
                method: "DELETE"
            });
    }
};

export const Guest = {
    /**
     * 
     * @param name Display name to be added
     * @param sessionId Session to add the guest
     * @returns Access token for the guest
     */
    addGuest: (name: string, sessionId: string): (dispatch: AppDispatch) => Promise<string> => async (dispatch): Promise<string> => {
        const result = await authFetch(baseUrl + "/auth/add", {
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({ name })
        });
        if (result.ok) {
            const addedUser = JSON.parse(await result.text());
            return await dispatch(Guest.generateGuestToken(addedUser.id, sessionId));
        }
        return "";
    },
    getGuests: () => async () => {
        const result = await authFetch(baseUrl + "/guest/list", {
            headers: {
                "Content-Type": "application/json"
            }
        });
        if (result.ok) {
            const json = await result.json();
            return json;
        }
    },
    removeGuest: (code: string) => async (dispatch: AppDispatch) => {
        await authFetch(baseUrl + `/guest/${code}`, {
            method: "DELETE"
        })
    },
    generateGuestToken: (userId: string, sessionId: string): () => Promise<string> => async (): Promise<string> => {
        const result = await authFetch(baseUrl + '/guest/token', {
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ sessionId, userId }),
            method: "POST"
        });
        return await result.text();
    }
};

function addAuthHeader(options?: RequestInit): RequestInit {
    const token = localStorage.getItem("token");

    if (!options) {
        options = { headers: {} };
    }

    if (token) options.headers = { ...options.headers, authorization: `Bearer ${token}` };
    return options;
}
