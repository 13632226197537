import { useEffect, useRef, useState } from "react";
import "./Pointer.css";

export default function Pointer({ x, y, name, colour }: { x: number, y: number, name: string, colour?: string }) {
    const [visibility, setVisibility] = useState(true);
    const timeoutRef = useRef<NodeJS.Timeout>();
    const colourRef = useRef(colour);

    useEffect(() => {
        if(timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            setVisibility(false);
        }, 5000);
        setVisibility(true);
    }, [x, y]);

    useEffect(() => {
        if (!colour) {
            const r = Math.floor((Math.random() * 1000)) % 255;
            const g = Math.floor((Math.random() * 1000)) % 255;
            const b = Math.floor((Math.random() * 1000)) % 255;
            colourRef.current = `rgb(${r},${g},${b})`;
        }
    }, []);

    return <>
        {visibility && <>
            <div className="pointer" style={{ position: "absolute", top: y, left: x, backgroundColor: colourRef.current }}>
                {name}
            </div>
        </>}
    </>;
}