import React, { useRef, useEffect, useState, useMemo } from 'react';
import MonacoEditor from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { createAction, hubInvoke } from '../state/actions';
import { sendPosition } from '../middleware/signalr';
import Pointer from './Pointer';

const Editor = ({ height }: {height: number}) => {
    const dispatch = useAppDispatch();
    const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
    const { text, inControl, connectionId, sessionId, userName } = useAppSelector((state) => state.main);
    const positions = useAppSelector(state => state.positions);


    useEffect(() => {
        if (sessionId !== "" && userName) {
            editorRef.current?.onMouseMove(e => {
                const { posx, posy } = e.event;
                const pos = `${posx}:${posy}|${userName}`;
                dispatch(sendPosition(pos, sessionId));
            });
        }
    }, [dispatch, sessionId, userName]);

    return <>
        <MonacoEditor
            theme="vs-dark"
            height={height}
            language="javascript"
            value={text}
            onChange={(currentText, e) => {
                if (inControl === connectionId) {
                    dispatch(hubInvoke("sendText", sessionId, currentText))
                }
            }}
            onMount={(editor, _monaco) => {
                editorRef.current = editor;
                editor.onDidChangeCursorSelection(e => {
                    // const { startLineNumber, endLineNumber, startColumn, endColumn } = e.selection;
                    // if (selectionChanged && (startLineNumber !== endLineNumber || startColumn !== endColumn)) {
                    //     selectionChanged(e.selection);
                    // }
                });
            }}
        />
        {positions && positions.map((p, index) => {
            const [xy, name] = p.split("|");
            const [x, y] = xy.split(":");
            return <Pointer key={index} name={name} x={parseInt(x)} y={parseInt(y)} />;
        })}
    </>;
}

export default Editor;
